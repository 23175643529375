import React, { useState } from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Footer from "../../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import newsData from "../../data/news";

const ITEMS_PER_PAGE = 10;

export default function OtherInitiatives() {
  const [expandedNews, setExpandedNews] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const toggleReadMore = (id) => {
    setExpandedNews((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const formatContent = (content) => {
    const formattedContent = content.replace(/<br><br>/g, "</p><p>");
    return `<p>${formattedContent}</p>`;
  };

  const paragraphStyles = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  const sortedNewsData = [...newsData].sort((a, b) => {
    if (a.date && b.date) {
      return new Date(b.date) - new Date(a.date);
    } else if (a.date) {
      return -1;
    } else if (b.date) {
      return 1;
    } else {
      return 0;
    }
  });

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = sortedNewsData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedNewsData.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const pairs = [];
  for (let i = 0; i < currentItems.length; i += 2) {
    pairs.push(currentItems.slice(i, i + 2));
  }

  return (
    <div>
      <NavBar />
      <center>
        <div>
          <h1 className="other-initiatives">OTHER INITIATIVES</h1>
        </div>

        <div className="other-initiatives-para-box">
          {pairs.map((pair, rowIndex) => (
            <div className="two-column-para" key={`row-${rowIndex}`}>
              {pair.map((newsItem) => (
                <div
                  className="column-para column1-para column2-para"
                  key={newsItem.id}
                >
                  <div style={{ padding: "10px" }}>
                    <img
                      src={newsItem.imageSrc}
                      alt={newsItem.title}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <div className="s4-a">
                      <h2 className="other-initiatives-topic">
                        {newsItem.title}
                      </h2>
                      {newsItem.date && (
                        <h3 className="other-initiatives-2nd-topic">
                          {newsItem.date}
                        </h3>
                      )}
                      <p
                        style={
                          expandedNews[newsItem.id] ? null : paragraphStyles
                        }
                      >
                        <p
                          className="other-initiatives-read-more-para"
                          dangerouslySetInnerHTML={{
                            __html: expandedNews[newsItem.id]
                              ? newsItem.content
                              : formatContent(newsItem.content),
                          }}
                        />
                        {newsItem.link && (
                          <p className="other-initiatives-read-more-para">
                            Read More –{" "}
                            <a
                              href={newsItem.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {newsItem.link}
                            </a>
                          </p>
                        )}
                      </p>
                      <center>
                        <div className="product-yarn-button-container-2">
                          <div className="read-more-bn-background-2">
                            <a
                              className="read-more-bn"
                              onClick={() => toggleReadMore(newsItem.id)}
                            >
                              {expandedNews[newsItem.id] ? (
                                <>
                                  Read less <FontAwesomeIcon icon={faArrowUp} />
                                </>
                              ) : (
                                <>
                                  Read more{" "}
                                  <FontAwesomeIcon icon={faArrowDown} />
                                </>
                              )}
                            </a>
                          </div>
                        </div>
                      </center>
                    </div>
                  </div>
                </div>
              ))}
              {pair.length === 1 && (
                <div className="column-para column-para-single"></div>
              )}
            </div>
          ))}
        </div>

        <div className="pagination-controls">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <button
              key={pageNumber + 1}
              className={currentPage === pageNumber + 1 ? "active" : ""}
              onClick={() => handlePageChange(pageNumber + 1)}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </center>
      <Footer />
    </div>
  );
}
